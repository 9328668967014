import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

let auth = "";
let decoded = "";

const date = new Date();

export const GuardedRoute = () => {
  auth = localStorage.getItem("auth_token");
  if (auth) {
    decoded = jwtDecode(auth);
    var expiry = decoded.exp * 1000;
    var current = Date.now();
    if (current >= expiry) {
      auth = null;
      localStorage.clear();
    }
  }
  return auth ? <Outlet /> : <Navigate to="/" replace={true} />;
};

export const GuardedLogin = () => {
  const auth = localStorage.getItem("auth_token");
  const role = localStorage.getItem("role");

  if (!auth) {
    return <Outlet />;
  } else if (role === "distributor") {
    return <Navigate to="/distributorPackage" replace={true} />;
  } else if (role === "admin") {
    return <Navigate to="/home" replace={true} />;
  }
};

//commmon
export const Guard_And_Navigate_To_Login = () => {
  auth = localStorage.getItem("auth_token");

  if (auth) {
    decoded = jwtDecode(auth);
    var expiry = decoded.exp * 1000;
    var current = Date.now();
    if (current >= expiry) {
      auth = null;
      localStorage.clear();
    }
  }
  return localStorage.getItem("auth_token") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

export const Guard_And_Navigate_To_LoginAdmin = () => {
  auth = localStorage.getItem("auth_token");
  const role = localStorage.getItem("role");
  if (auth && role === "admin") {
    decoded = jwtDecode(auth);
    var expiry = decoded.exp * 1000;
    var current = Date.now();
    if (current >= expiry) {
      auth = null;
      localStorage.clear();
    }
  }
  return localStorage.getItem("auth_token") && role === "admin" ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

export const Guard_And_Navigate_To_ResetPassword = () => {
  auth = localStorage.getItem("reset_forget_token");
  if (auth) {
    decoded = jwtDecode(auth);
    var expiry = decoded.exp * 1000;
    var current = Date.now();
    if (current >= expiry) {
      auth = null;
      localStorage.clear();
    }
  }
  return auth ? <Outlet /> : <Navigate to="/" replace={true} />;
};

export const Guard_And_Navigate_To_LoginDistributor = () => {
  const role = localStorage.getItem("role");
  auth = localStorage.getItem("auth_token");
  if (auth && role === "distributor") {
    decoded = jwtDecode(auth);
    var expiry = decoded.exp * 1000;
    var current = Date.now();
    if (current >= expiry) {
      auth = null;
      localStorage.clear();
    }
  }
  return localStorage.getItem("auth_token") && role === "distributor" ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

//------------------------------------------------------------------------------------------------------------
