import React from "react";
import DistributorHeader from "./DistributorHeader";
import { Link } from "react-router-dom";
// import InnerSideBarDistributor from "./InnerSideBarDistributor";

function ProfileDistributor() {
  return (
    <>
    
      <div id="main-wrapper">
      <DistributorHeader />
        <div className="content-body default-height default-height ml-0">
          <div className="container">
            <div className="form-head d-flex mb-3 align-items-start flex-wrap justify-content-between">
              <div className="me-auto d-lg-block">
                <h3 className="text-primary font-w600" />
              </div>
            </div>
            <section className="content profile mb-5 mt-5">
              <div className="checkout-sign">
                <div className="signin-form">
                  {/* <InnerSideBarDistributor /> */}
                  <div className="signin-page profile">
                    <figure className="my-account-photo">
                      <img src="images/profile.png" />
                      <i className="fa-solid fa-pen-to-square" />
                    </figure>
                    <div className="myaccount-information" id="myDIV">
                      <Link
                        to="/profileDistributor"
                        className="account-box-1 Checked active-2"
                      >
                        <h6>Profile</h6>
                        <i className="fa-solid fa-chevron-right" />
                      </Link>
                      <Link to="/changePassword" className="account-box-1">
                        <h6>Change Password</h6>
                        <i className="fa-solid fa-chevron-right" />
                      </Link>
                    </div>
                  </div>

                  <div className="signin-page-1">
                    <form className="signin-form-page profile-page">
                      <div className="signin-head">
                        <h2>Personal Information</h2>
                      </div>
                      <div className="detail-grid-1 profile">
                        <div className="form-group p-information">
                          <label>First Name</label>
                          <span>Johny </span>
                        </div>
                        <div className="form-group p-information">
                          <label>Last Name</label>
                          <span>Wise</span>
                        </div>
                        <div className="form-group p-information">
                          <label>Address</label>
                          <span>
                            Suite 257 811 Green Curve, North Farah, IL 98590
                          </span>
                        </div>
                        <div className="form-group p-information">
                          <label>Mobile No.</label>
                          <span>+91 9876543210</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <section className="col-lg-5 connectedSortable ui-sortable"></section>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileDistributor;
