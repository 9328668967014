import { Formik, Form } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { MyTextInput } from "../services/authentication/inputServices";
import { change_password } from "../services/web/webservices";
import { useNavigate, Link } from "react-router-dom";
import { Store } from "react-notifications-component";
import SideBar from "./SideBar";
import Header from "./Header";
import InerSideBar from "./InerSideBar";
import DistributorHeader from "./DistributorHeader";

function ChangePassword() {
  const navigate = useNavigate();
  const [getButton, setButton] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const role = localStorage.getItem("role");

  

  const togglePasswordVisibility = (field) => {
    setPasswordVisible({
      ...passwordVisible,
      [field]: !passwordVisible[field],
    });
  };

  const css = `
  
  
  .additional-u {
    position: relative;
  }
  
  .name-input {
    position: relative;
  }
  
  .name-input input {
    width: calc(100% - 30px);
    padding-right: 30px;
  }
  
  .name-input i {
    position: absolute;
    top: 30%;
    transform: translateY(-35%);
    right: 25px; 
    cursor: pointer;
    z-index: 1;
  `;
  const [isToggled, setIsToggled] = useState(false);
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };

  return (
    <>
      <div
        id="main-wrapper"
        className={isToggled ? "show menu-toggle" : "show"}
      >
        {role === "admin" ? (
          <>
            <div className="nav-header">
              <Link to="/home" className="brand-logoo">
                <img
                  className="logo-abbr"
                  src="images/fast-delivery.png"
                  alt="logo"
                />
                <h3 className="nav-text">Boy Blue Delivery</h3>
              </Link>
              <div className="nav-control">
                <div
                  className={isToggled ? "hamburger is-active" : "hamburger"}
                  onClick={toggleClass}
                >
                  <span className="line" />
                  <span className="line" />
                  <span className="line" />
                </div>
              </div>
            </div>
            <SideBar />
            <Header pageName="Change Password" />
          </>
        ) : (
          <>
            <DistributorHeader />
            {/* <InnerSideBarDistributor /> */}
          </>
        )}
        <style>{css}</style>
        <div className="content-body default-height default-height">
          <div className="container-fluid">
            <div className="form-head d-flex mb-3 align-items-start flex-wrap justify-content-between">
              <div className="me-auto d-lg-block">
                <h3 className="text-primary font-w600" />
              </div>
            </div>
            <section className="content profile mb-5 mt-5">
              <div className="checkout-sign">
                <div className="signin-form">
                  <InerSideBar />
                  <Formik
                    initialValues={{
                      old_password: "",
                      password: "",
                      confirm_password: "",
                    }}
                    validationSchema={Yup.object({
                      old_password: Yup.string().required(
                        "old password is required"
                      ),
                      password: Yup.string()
                        .required(" new password is required.")
                        .min(
                          8,
                          "Password is too short - should be 8 characters minimum."
                        )
                        .notOneOf(
                          [Yup.ref("old_password"), null],
                          "New password must be different from old password"
                        )
                        .matches(
                          /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                          "Password must contain at least one number, one special character, one uppercase letter, and one lowercase letter"
                        ),
                      confirm_password: Yup.string()

                        .oneOf(
                          [Yup.ref("password"), null],
                          "new password must match"
                        )
                        .required("confirm password is required"),
                    })}
                    onSubmit={(values, { resetForm }) => {
                      setButton(false);
                      change_password(
                        values,
                        localStorage.getItem("auth_token")
                      )
                        .then((res) => {
                          setButton(true);
                        
                          Store.addNotification({
                            title: "Success",
                            message: res?.data?.message,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: [
                              "animate__animated",
                              "animate__fadeIn",
                            ],
                            animationOut: [
                              "animate__animated",
                              "animate__fadeOut",
                            ],
                            dismiss: {
                              duration: 5000,
                              onScreen: true,
                            },
                          });
                          resetForm({ values: "" });

                          navigate("/profile");
                        })
                        .catch((err) => {
                          setButton(true);
                          if (err) {
                           
                            Store.addNotification({
                              title: "Error!",
                              message: err?.response?.data?.message,
                              type: "danger",
                              insert: "top",
                              container: "top-right",
                              animationIn: [
                                "animate__animated",
                                "animate__fadeIn",
                              ],
                              animationOut: [
                                "animate__animated",
                                "animate__fadeOut",
                              ],
                              dismiss: {
                                duration: 5000,
                                onScreen: true,
                              },
                            });
                          }
                        });
                    }}
                  >
                    {(props) => (
                      <div className="signin-page-1">
                        <Form className="signin-form-page profile-page">
                          <div className="signin-head">
                            <h2>Change Password</h2>
                          </div>
                          <div className="detail-grid-1 chngpswrd">
                            <div className="additional-u change-password-space">
                              <label className="name">Old Password</label>
                              <span className="name-input">
                                <MyTextInput
                                  type={
                                    passwordVisible.oldPassword
                                      ? "text"
                                      : "password"
                                  }
                                  className="input-text"
                                  name="old_password"
                                  placeholder="Enter your old password"
                                />
                                <i
                                  className={`fa ${
                                    passwordVisible.oldPassword
                                      ? "fa-eye-slash"
                                      : "fa-eye"
                                  }`}
                                  onClick={() =>
                                    togglePasswordVisibility("oldPassword")
                                  }
                                />
                              </span>
                            </div>

                            <div className="additional-u change-password-space">
                              <label className="name">New Password</label>
                              <span className="name-input">
                                <MyTextInput
                                  type={
                                    passwordVisible.newPassword
                                      ? "text"
                                      : "password"
                                  }
                                  className="input-text"
                                  name="password"
                                  placeholder="Enter your new password"
                                />
                                <i
                                  className={`fa ${
                                    passwordVisible.newPassword
                                      ? "fa-eye-slash"
                                      : "fa-eye"
                                  }`}
                                  onClick={() =>
                                    togglePasswordVisibility("newPassword")
                                  }
                                />
                              </span>
                            </div>
                            <div className="additional-u change-password-space">
                              <label className="name">Confirm Password</label>
                              <span className="name-input">
                                <MyTextInput
                                  type={
                                    passwordVisible.confirmPassword
                                      ? "text"
                                      : "password"
                                  }
                                  className="input-text"
                                  name="confirm_password"
                                  placeholder="confirm password"
                                />
                                <i
                                  className={`fa ${
                                    passwordVisible.confirmPassword
                                      ? "fa-eye-slash"
                                      : "fa-eye"
                                  }`}
                                  onClick={() =>
                                    togglePasswordVisibility("confirmPassword")
                                  }
                                />
                              </span>
                            </div>
                            {/* <div className="signin-button">
                              <button
                                type="submit"
                                className="learn-more-btn-1-1"
                              >
                              
                              </button>
                            </div> */}
                            {getButton === true ? (
                              <div className="signin-button">
                                <button
                                  type="submit"
                                  className="learn-more-btn-1-1"
                                >
                                  Submit
                                </button>
                              </div>
                            ) : (
                              <div className="signin-button">
                                <button
                                  type="submit"
                                  className="learn-more-btn-1-1"
                                >
                                  Wait Please...
                                </button>
                              </div>
                            )}
                          </div>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
              <section className="col-lg-5 connectedSortable ui-sortable"></section>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
