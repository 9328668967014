import React, { useEffect, useState } from "react";
import DistributorHeader from "./DistributorHeader";
import { get_products_admin } from "../services/web/webservices";
import { Store } from "react-notifications-component";
import { Modal, Button } from "react-bootstrap";
import image_url from "../api/base_url";
import ReactPaginate from "react-paginate";
import moment from "moment";

function DistributorPackageDetail() {
  const [showDistributorModal, setShowDistributorModal] = useState(false);
  const [productRowData, setSelectedRowData] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [key, setKey] = useState(Date.now());
  const [totalRows, setTotalRows] = useState(0);

  const openModal = (rowData) => {
    setSelectedRowData(rowData);
    setShowDistributorModal(true);
  };

  const closeModal = () => {
    setShowDistributorModal(false);
  };
  const getRangeText = () => {
    const startRow = currentPage * limit + 1;
    const endRow = Math.min((currentPage + 1) * limit, totalRows);
    return `${startRow}-${endRow} out of ${totalRows}`;
  };
  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  let limit = 10;
  let notificationDisplayed = false;
  const getProducts = async (currentPage = 0, limit, search_key = "") => {
    try {
      const res = await get_products_admin(
        currentPage,
        limit,
        selectedOption,
        fromDate,
        toDate,
        "",
        search_key
      );
      if (!res || !res.data || !res.data.meta || !res.data.meta.pagination) {
        return;
      }

      setpageCount(res.data.meta.pagination.totalPageCount);
      setItems(res.data);
      setTotalRows(res.data.meta.pagination.totalCount);
    } catch (error) {
      if (!notificationDisplayed) {
        notificationDisplayed = true;

        Store.addNotification({
          title: "Error!",
          message: error?.response?.data?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
          onRemoval: () => {
            notificationDisplayed = false;
          },
        });
      }
    }
  };

  useEffect(() => {
    getProducts(currentPage, limit);
  }, []);

  const handlePageClick = async (data) => {
    const currentPage1 = data.selected;
    setCurrentPage(currentPage1);

    getProducts(data.selected, limit);
  };

  const css = `
.table-container {
  overflow-x: auto; /* Horizontal scrollbar when table overflows */
  overflow-y: auto; /* Vertical scrollbar when table overflows */
  max-width: 100%; /* Ensure the table container doesn't exceed its parent width */
  max-height: 700px; /* Define the maximum height for vertical scrolling */
}

`;
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value == "") {
      setCurrentPage(0);
      getProducts(0, limit);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(0);
    getProducts(0, limit, searchValue);
    setKey(Date.now());
  };

  const handleToDateChange = (event) => {
    const selectedDate = event.target.value;
    setToDate(selectedDate);
  };
  const handleFromDateChange = (event) => {
    const selectedDate1 = event.target.value;
    setFromDate(selectedDate1);
  };
  const handleApplyFilter = async () => {
    setCurrentPage(0);
    getProducts(0, limit);
    setKey(Date.now());
  };
  const handleClearChange = () => {
    window.location.reload();
  };

  return (
    <>
      <div id="main-wrapper">
        <DistributorHeader />
        <style>{css}</style>
        <div className="content-body default-height default-height ml-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                {/* ------------------------------------------------- */}
                <div className="form-head d-flex mb-3 align-items-start flex-wrap justify-content-between">
                  <div className="me-auto d-lg-block">
                    <h3 className="text-primary font-w600"> </h3>
                  </div>

                  <div className="col-lg-6 mb-4  ">
                    <div className="reservation-box">
                      <div className="top">
                        <div className="static">
                          <div
                            className="input-container"
                            id="date-picker-container"
                          >
                            <label htmlFor="date-from">From date</label>
                            <input
                              type="date"
                              id="date-checkin"
                              className="date-field"
                              name="date-from"
                              value={fromDate}
                              onChange={handleFromDateChange}
                            />
                          </div>
                        </div>
                        <div
                          className="input-container"
                          id="date-picker-container"
                        >
                          <label htmlFor="date-from">To date</label>
                          <input
                            type="date"
                            id="date-checkout"
                            className="date-field"
                            name="date-to"
                            value={toDate}
                            onChange={handleToDateChange}
                          />
                        </div>

                        <div className="flex d-flex justify-content-center">
                          <div className="button-container">
                            <span
                              className="button ok filter"
                              onClick={handleApplyFilter}
                            >
                              {" "}
                              Apply
                            </span>
                            <span
                              className="button ok filter"
                              onClick={handleClearChange}
                            >
                              Clear
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form
                    className="default-search"
                    onSubmit={handleSearch}
                    style={{ marginLeft: "230px" }}
                  >
                    <div className="input-group search-area ms-auto w-100 d-inline-flex ">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={searchValue}
                        onChange={handleSearchChange}
                      />
                      <span class="input-group-text">
                        <button
                          className="bg-transparent border-0"
                          type="submit"
                        >
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
                {/* ----------------------------------------------------------- */}

                <div className="upload-btn details">
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>Delivery Status</h3>
                  </div>

                  <div className="table-container">
                    <table className="table table-responsive csv-file-section Details">
                      <thead>
                        <tr className="upper-heading">
                          <th className="u-heading">Tracking Number</th>
                          <th className="u-heading">Salon Name</th>
                          <th className="u-heading">Cutomer Name</th>
                          <th className="u-heading">Status</th>
                          <th className="u-heading">Delivery Date</th>
                          <th className="u-heading">Invoice Date</th>
                          <th className="u-heading">Remark</th>
                          <th className="u-heading">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items?.data?.map((rowData, index) => (
                          <tr key={index} className="lower-heading">
                            <td className="u-heading">
                              {rowData?.tracking_no
                                ? rowData?.tracking_no
                                : "NA"}
                            </td>
                            <td className="u-heading">
                              {rowData?.salon_name ? rowData?.salon_name : "NA"}
                            </td>
                            <td className="u-heading">
                              {rowData?.customer_name
                                ? rowData?.customer_name
                                : "NA"}
                            </td>

                            <td className="u-heading color-green">
                              {rowData?.status == "deliverd"
                                ? "Delivered"
                                : rowData?.status == "not-deliverd"
                                ? "Not Delivered"
                                : rowData?.status}
                            </td>
                            <td className="u-heading">
                              {/* {rowData?.delivery_date} */}
                              {rowData?.status == "deliverd" &&
                              rowData?.delivery_date
                                ? moment(rowData.delivery_date).format(
                                    "D MMM, YYYY, h:mm:ss A"
                                  )
                                : rowData?.status_date
                                ? moment(rowData.status_date).format(
                                    "D MMM, YYYY, h:mm:ss A"
                                  )
                                : "-"}
                            </td>

                            <td className="u-heading">
                              {moment(rowData?.invoice_date_full).format(
                                "D MMMM,YYYY"
                              )}
                            </td>
                            <td className="u-heading">
                              {rowData?.remark ? rowData?.remark : "NA"}
                            </td>
                            <td className="u-heading">
                              <i
                                className="bi bi-eye-fill"
                                title="View Detail"
                                onClick={() => openModal(rowData)}
                                style={{
                                  cursor: "pointer",
                                  marginRight: "40px",
                                }}
                              ></i>{" "}
                            </td>
                          </tr>
                        ))}
                        <Modal
                          show={showDistributorModal}
                          onHide={closeModal}
                          size="lg"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title style={{ color: "#0f5a94" }}>
                              Deliverd Packages
                            </Modal.Title>
                          </Modal.Header>

                          <Modal.Body>
                            <div className="row mb-6">
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Tracking Number
                                </h6>
                                <p>
                                  {productRowData?.tracking_no
                                    ? productRowData?.tracking_no
                                    : "NA"}
                                </p>
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Customer Name
                                </h6>
                                {productRowData?.customer_name
                                  ? productRowData?.customer_name
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Salon Name
                                </h6>
                                {productRowData?.salon_name
                                  ? productRowData?.salon_name
                                  : "NA "}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Customer Phone
                                </h6>
                                {productRowData?.customer_phone
                                  ? productRowData?.customer_phone
                                  : "NA "}
                              </div>
                            </div>
                            {/* -------------------------------------------------------------- */}
                            <div className="row mb-6">
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Shipping Address 1
                                </h6>
                                {productRowData?.shipadd1
                                  ? productRowData?.shipadd1
                                  : "NA"}
                              </div>
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Shipping Address 2
                                </h6>
                                {productRowData?.shipadd2
                                  ? productRowData?.shipadd2
                                  : "NA"}
                              </div>
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Shipping City
                                </h6>
                                {productRowData?.shipcity
                                  ? productRowData?.shipcity
                                  : "NA"}
                              </div>
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Shipping State
                                </h6>
                                {productRowData?.shipstate
                                  ? productRowData?.shipstate
                                  : "NA"}
                              </div>
                            </div>

                            {/* ----------------------------------------------------------- */}
                            <div className="row mb-6">
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Shipping Zip Code
                                </h6>
                                {productRowData?.shipzip
                                  ? productRowData?.shipzip
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Shipping Via
                                </h6>
                                {productRowData?.shipvia
                                  ? productRowData?.shipvia
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Box No
                                </h6>
                                {productRowData?.cbox_box
                                  ? productRowData?.cbox_box
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Num of Carton
                                </h6>
                                {productRowData?.num_of_carton
                                  ? productRowData?.num_of_carton
                                  : "NA"}
                              </div>
                            </div>
                            {/* ------------------------------------------------------- */}
                            <div className="row mb-6">
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  OrderType
                                </h6>
                                {productRowData?.order_type
                                  ? productRowData?.order_type
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  COD
                                </h6>
                                {productRowData?.cod
                                  ? productRowData?.cod
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Invoice Number
                                </h6>
                                <p>
                                  {productRowData?.invoice_number
                                    ? productRowData?.invoice_number
                                    : "NA"}
                                </p>
                              </div>
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Invoice Date
                                </h6>
                                <p>
                                  {productRowData?.Invoice_Date
                                    ? productRowData?.invoice_date
                                    : "NA"}
                                </p>
                              </div>
                            </div>
                            {/* ------------------------------------------------- */}
                            <div className="row mb-6">
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Invoice Year
                                </h6>
                                {productRowData?.invoice_year
                                  ? productRowData?.invoice_year
                                  : "NA"}
                              </div>
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Invoice Month
                                </h6>
                                {productRowData?.invoice_month
                                  ? productRowData?.invoice_month
                                  : "NA"}
                              </div>
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Invoice Day
                                </h6>
                                {productRowData?.invoice_day
                                  ? productRowData?.invoice_day
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                    marginTop: "18px",
                                  }}
                                >
                                  Delivery Date
                                </h6>
                                {productRowData?.status == "deliverd" &&
                                productRowData?.delivery_date ? (
                                  moment(productRowData.delivery_date).format(
                                    "D MMM, YYYY, h:mm:ss A"
                                  )
                                ) : productRowData?.status_date ? (
                                  moment(productRowData.status_date).format(
                                    "D MMM, YYYY, h:mm:ss A"
                                  )
                                ) : (
                                  <p>Delivery Date not found</p>
                                )}
                              </div>

                              <div className="col-md-12">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Comment
                                </h6>
                                {productRowData?.comment ? (
                                  <p>{productRowData?.comment}</p>
                                ) : (
                                  <p>comment not found</p>
                                )}
                              </div>
                              <div
                                className="col-md-6"
                                style={{ marginTop: "20px" }}
                              >
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Image
                                </h6>

                                {productRowData?.image ? (
                                  <img
                                    src={`${image_url.image_url}/${productRowData?.image}`}
                                    alt="Product Image"
                                  />
                                ) : (
                                  <p>Image not found</p>
                                )}
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={closeModal}
                              style={{ backgroundColor: "#0f5a94" }}
                            >
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </tbody>
                    </table>
                    <ReactPaginate
                      key={key}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                    <p style={{ margin: "30px" }}>{getRangeText()}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DistributorPackageDetail;
