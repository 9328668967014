import axios from "axios";

import base_uri from "../../api/base_url";

export const login = (data) => {
  return axios({
    method: "post",
    url: `${base_uri.api_url}/api/v1/auth/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
};

export const forgot = (data) => {
  return axios({
    method: "post",
    url: `${base_uri.api_url}/api/v1/auth/forgot_password`,
    data: data,
  });
};

export const verifyemail = (token) => {
  return axios({
    method: "post",
    url: `${base_uri.api_url}/api/v1/auth/verify_forgot_password`,
    data: { token: token, verification_type: "password" },
  });
};

export const reset_password = (values, token) => {
  // const token=localStorage.getItem('reset_forget_token');
  console.log("resetTokennnnnnnnn", token);
  return axios({
    method: "post",
    url: `${base_uri.api_url}/api/v1/auth/reset_password`,
    headers: { Authorization: `Bearer ${token}` },
    // data: { password: values.password },
    data: values,
  });
};

export const change_password = (values) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.api_url}/api/v1/auth/change_password`,
    data: values,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const get_profile = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.api_url}/api/v1/auth/get_profile`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const create_distributor = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.api_url}/api/v1/users`,
    headers: { Authorization: `Bearer ${token}` },
    data: data,
  });
};
export const edit_profile = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.api_url}/api/v1/auth/update_profile`,
    headers: { Authorization: `Bearer ${token}` },
    data: data,
  });
};

export const get_distributor = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.api_url}/api/v1/users`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const get_products_bystatus = (currentPage, limit) => {
  const token = localStorage.getItem("auth_token");

  return axios({
    method: "get",
    url: `${base_uri.api_url}/api/v1/products/product_filter?pagination={"currentPage":${currentPage},"docPerPageCount":${limit}}&status=deliverd`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const upload_csv = (data, onUploadProgress) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.api_url}/api/v1/products/uploadcsv`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },

    data: data,
    onUploadProgress: onUploadProgress,
  });
};

export const get_products = () => {
  const token = localStorage.getItem("auth_token");

  return axios({
    method: "get",
    url: `${base_uri.api_url}/api/v1/products`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const update_profile = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.api_url}/api/v1/auth/update_profile`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};

export const getDistributorByID = (userId) => {
  const token = localStorage.getItem("auth_token");

  return axios({
    method: "get",
    url: `${base_uri.api_url}/api/v1/users/${userId}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateDistributor = (data, userId) => {
  const token = localStorage.getItem("auth_token");

  return axios({
    method: "put",
    url: `${base_uri.api_url}/api/v1/users/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};

export const delete_distributor_byId = (userId) => {
  const token = localStorage.getItem("auth_token");

  return axios({
    method: "delete",
    url: `${base_uri.api_url}/api/v1/users/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const get_products_admin = (currentPage, limit,status="",date_from="",date_to="",created_for="",search_key='') => {
  const token = localStorage.getItem("auth_token");
  let querySting=`pagination={"currentPage":${currentPage},"docPerPageCount":${limit}}`;
  if(status !=""){
    querySting=querySting+`&status=${status}`
  }
  if(date_from !=""){
    querySting=querySting+`&date_from=${date_from}`
  }
  if(date_to !=""){
    querySting=querySting+`&date_to=${date_to}`
  }
  if(created_for !=""){
    querySting=querySting+`&created_for=${created_for}`
  }
  if(search_key !=""){
    querySting=querySting+`&search_key=${search_key}`
  }

  let url=`${base_uri.api_url}/api/v1/products/product_filter?`+querySting;
  

  return axios({
    method: "get",
    
    url: url,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const update_status_byId = (data, userId) => {
  const token = localStorage.getItem("auth_token");

  return axios({
    method: "put",
    url: `${base_uri.api_url}/api/v1/products/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {status:data},
  });
};

export const updateProductById = (data, productId) => {
  const token = localStorage.getItem("auth_token");

  return axios({
    method: "put",
    url: `${base_uri.api_url}/api/v1/products/${productId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};

export const delete_product_byId = (userId) => {
  const token = localStorage.getItem("auth_token");

  return axios({
    method: "delete",
    url: `${base_uri.api_url}/api/v1/products/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const delete_Mutiple_product = (data) => {
  const token = localStorage.getItem("auth_token");

  return axios({
    method: "delete",
    url: `${base_uri.api_url}/api/v1/products/multidelete`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  });
};
export const delete_Mutiple_product_track = (data) => {
  const token = localStorage.getItem("auth_token");

  return axios({
    method: "delete",
    url: `${base_uri.api_url}/api/v1/products/multideletetrac`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  });
};