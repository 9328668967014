import React, { useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import { Link, useNavigate } from "react-router-dom";
import { get_profile } from "../services/web/webservices";
function Header(props) {
  const navigate = useNavigate();

  const [getDetail, setDetail] = useState("");

  useEffect(() => {
    get_profile()
      .then((res) => {
      
        setDetail(res.data.data);
      })
      .catch((err) => {
        if (err) {
          // console.log("------>", err);
        }
      });
  }, []);
  
  const handlelogout = () => {
    if (window.confirm("Are You Sure To Logout Your Account")) {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("role");

      Store.addNotification({
        title: "Success",
        message: "Logged out Successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });

      navigate("/");
    }
  };

  return (
    <>
      {/* <div className="nav-header">
        <Link to="/home" className="brand-logoo">
          <img className="logo-abbr" src="images/fast-delivery.png" alt="logo" />
          <h3 className="nav-text">Boy Blue Delivery</h3>
        </Link>
        <div className="nav-control" >
          <div className="hamburger">
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </div>
        </div>
      </div> */}

      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div className="dashboard_bar">{props?.pageName}</div>
              </div>
              <ul className="navbar-nav header-right">
              
                <li className="nav-item dropdown header-profile">
                  <a
                    className="nav-link"
                    // href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    <div className="header-info">
                      <span  style={{textTransform: "capitalize"}}>{getDetail?.first_name} {getDetail?.last_name}</span>
                      <small  style={{textTransform: "capitalize"}}>{getDetail?.role}</small>
                    </div>
                    <img
                      src={getDetail?.profile_picture}
                      width={20}
                      alt="profilePicture"
                    />
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <Link to="/profile" className="dropdown-item ai-icon">
                      <svg
                        id="icon-user1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                      <span className="ms-2">Profile </span>
                    </Link>
                    <button
                      className="dropdown-item ai-icon"
                      onClick={handlelogout}
                    >
                      <svg
                        id="icon-logout"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-danger"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1={21} y1={12} x2={9} y2={12} />
                      </svg>
                      <span className="ms-2">Logout </span>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Header;
