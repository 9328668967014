import React, { useState } from "react";
import { NavLink, Link, useLocation} from "react-router-dom";

function SideBar() {
  
  const [getHomeMenu, setHomeMenu] = useState([ 'home','deliveryPackage', 'createDistributor', 'manageDistributor','editManageDistributor','allPackage']);
  const [getProfileMenu, setProfileMenu] = useState([ 'profile','editProfile', 'changePassword']);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  let location = useLocation();

 
  let currentRoute = location.pathname;
  currentRoute = currentRoute.split('/');
  currentRoute = currentRoute[1];
  
  const handleClick=()=>{
    if(isSubMenuOpen==true){
      setIsSubMenuOpen(false);
    }else{
      setIsSubMenuOpen(true);
    }
   

  }
  return (
    <>
      <div className="deznav">
        <div className="deznav-scroll mm-active">
          <ul  className={(getHomeMenu.includes(currentRoute) || isSubMenuOpen) ? 'metismenu mm-show' : 'metismenu'} id="menu">
            <li className={(getHomeMenu.includes(currentRoute)||isSubMenuOpen) ?  'mm-active'  : ''} onClick={handleClick}>
            <Link  className="has-arrow ai-icon"
                aria-expanded={getHomeMenu.includes(currentRoute) ?  true : false}
                style={{ cursor: "pointer" }}>
                <i className="fa-solid fa-truck"></i>
                <span className="nav-text">Delivery Details</span>
                </Link>
              <ul aria-expanded={getHomeMenu.includes(currentRoute) ? true : false} className={(getHomeMenu.includes(currentRoute)|| isSubMenuOpen) ? 'mm-collapse mm-show' : 'mm-collapse'}>
                <li>
                <Link to="/home"  className={currentRoute=='home' ?  'mm-active'  : ''}>
                    Upload CSV file &amp; Details
                    </Link>
                </li>
                <li>
                <Link to="/allPackage"  className={currentRoute=='allPackage' ?  'mm-active'  : ''}>
                   All Packages Details
                    </Link>
                </li>
                <li>
                <Link to="/deliveryPackage" className={currentRoute=='deliveryPackage' ?  'mm-active'  : ''}>
                    Delivered Packages
                    </Link>
                </li>
                <li>
                <Link to="/createDistributor" className={currentRoute=='createDistributor' ?  'mm-active'  : ''}>
                    Create Distributor
                    </Link>
                </li>
                <li>
                <Link to="/manageDistributor" className={(currentRoute=='manageDistributor' || currentRoute=='editManageDistributor') ?  'mm-active'  : ''}> Manage Distributor </Link>
                </li>
              </ul>
            </li>
            <li className={getProfileMenu.includes(currentRoute) ? 'mm-active active-no-child' : ''}>
            <Link to="/profile"  className={currentRoute=='profile' ?  'mm-active'  : ''}>
                <i className="fa-regular fa-user" style={{color: "inherit"}}></i>
                <span className="nav-text">Manage Profile</span>
             </Link>
            </li>
          </ul>
        </div>
        
      </div>
    </>
  );
}

export default SideBar;



