import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Store } from "react-notifications-component";
import { useNavigate } from "react-router-dom";
import { MyTextInput } from "../services/authentication/inputServices";
import { reset_password } from "../services/web/webservices";

function ResetPassword() {
  const navigate = useNavigate();
  const [getButton, setButton] = useState(true);

  const [visibility, setVisibility] = useState({
    field1: false,
    field2: false,
  });

  const togglePasswordVisibility = (field) => {
    setVisibility({
      ...visibility,
      [field]: !visibility[field],
    });
  };

  const css = `
  /* Add this CSS to your stylesheet */
.signup__field {
  position: relative;
}

.name-input {
  position: relative;
}

.signup__input {
  padding-right: 30px; /* Adjust the padding to accommodate the eye icon */
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
}

  `;

  return (
    <>
      <style>{css}</style>
      <div className="sign-screen">
        <section className="sectionn">
          <div className="container-fluid-1">
            <div className="row">
              <div className="col-md-6 padding-1">
                <div className="form-part">
                  <p
                    className="nav-text"
                    style={{ color: "#0f5a94", fontWeight: "bold" }}
                  >
                    Boy Blue Delivery
                  </p>
                  <h2 className="mb-5">RESET PASSWORD</h2>

                  <Formik
                    initialValues={{
                      password: "",
                      confirm_password: "",
                    }}
                    validationSchema={Yup.object({
                      password: Yup.string()
                        .min(
                          8,
                          "Password is too short - should be 8 characters minimum."
                        )
                        .required()
                        .matches(
                          /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                          "Password should be between 8 to 16 characters and should include 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character"
                        ),
                      confirm_password: Yup.string()
                        .oneOf(
                          [Yup.ref("password"), null],
                          "Passwords must match"
                        )
                        .required("Confirm Password is required."),
                    })}
                    onSubmit={(values, { resetForm }) => {
                      setButton(false);

                      reset_password(
                        values,
                        localStorage.getItem("reset_forget_token")
                      )
                        .then((res) => {
                          setButton(true);

                       
                          Store.addNotification({
                            title: "Success",
                            message: res?.data?.message,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: [
                              "animate__animated",
                              "animate__fadeIn",
                            ],
                            animationOut: [
                              "animate__animated",
                              "animate__fadeOut",
                            ],
                            dismiss: {
                              duration: 5000,
                              onScreen: true,
                            },
                          });

                          resetForm({ values: "" });

                          localStorage.removeItem("reset_forget_token");

                          navigate("/");
                       
                        })
                        .catch((err) => {
                          setButton(true);
                          if (err) {
                          
                            Store.addNotification({
                              title: "Error!",
                              message: err?.response?.data?.message,
                              type: "danger",
                              insert: "top",
                              container: "top-right",
                              animationIn: [
                                "animate__animated",
                                "animate__fadeIn",
                              ],
                              animationOut: [
                                "animate__animated",
                                "animate__fadeOut",
                              ],
                              dismiss: {
                                duration: 5000,
                                onScreen: true,
                              },
                            });
                            localStorage.removeItem("reset_forget_token");
                            navigate("/");
                          }
                        });
                    }}
                  >
                    {(props) => (
                      <div className="right col">
                        <Form className="sign-up">
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="signup__field">
                                <span className="name-input">
                                  <MyTextInput
                                    type={
                                      visibility.field1 ? "text" : "password"
                                    }
                                    className="signup__input"
                                    // type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Password"
                                    required
                                    autoComplete="password"
                                    aria-hidden="true"
                                  />
                                  <i
                                    className={`fa ${
                                      visibility.field1
                                        ? "fa-eye-slash"
                                        : "fa-eye"
                                    } eye-icon`}
                                    onClick={() =>
                                      togglePasswordVisibility("field1")
                                    }
                                  />
                                </span>
                              </div>

                              <div className="signup__field">
                                <span className="name-input">
                                  <MyTextInput
                                    className="signup__input"
                                    type={
                                      visibility.field2 ? "text" : "password"
                                    }
                                    name="confirm_password"
                                    id="confirm_password"
                                    placeholder="Confirm Password"
                                    required
                                    autoComplete="new-password"
                                  />
                                  <i
                                    className={`fa ${
                                      visibility.field2
                                        ? "fa-eye-slash"
                                        : "fa-eye"
                                    } eye-icon`}
                                    onClick={() =>
                                      togglePasswordVisibility("field2")
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          </div>

                          {getButton === true ? (
                            <button type="submit" className="btn submit-btn">
                              Reset Password
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn submit-btn"
                              disabled={!getButton}
                            >
                              Wait Please
                            </button>
                          )}
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="col-md-6 bg-grey">
                <div className="text-part text-center pt-5">
                  <img src="images/login-1.png" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ResetPassword;
