import React,{useEffect,useState} from "react";
import { Link } from "react-router-dom";
import { Store } from "react-notifications-component";
import { useNavigate } from "react-router-dom";
import { get_profile } from "../services/web/webservices";

function DistributorHeader() {
  const [getDetailDistributor, setDetailDistributor] = useState("");
  const navigate = useNavigate();

  const handleDistributorLogout = () => {
    if (window.confirm("Are you sure you want to logout")) {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("role");

      Store.addNotification({
        title: "Success",
        message: "logout Sucessfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });

      navigate("/login");
    }
  };

  useEffect(() => {
    get_profile()
      .then((res) => {
       
        setDetailDistributor(res.data.data);
      })
      .catch((err) => {
        if (err) {
          // console.log("------>", err);
        }
      });
  }, []);
  return (
    <>
      <div className="header distributor pl-0">
        <div className="container">
          <div className="header-content distributor pl-0">
            <nav className="navbar navbar-expand">
              <div className="collapse navbar-collapse justify-content-between">
                <div className="nav-header distributor">
                  <Link to="/distributorpackage" className="brand-logoo">
                    <img
                      className="logo-abbr"
                      src="images/fast-delivery.png"
                      alt="Logo Image"
                    />
                    <h3 className="nav-text">Boy Blue Delivery</h3>
                  </Link>
                </div>
                <ul className="navbar-nav header-right">
                  <li className="nav-item dropdown header-profile">
                    <Link
                      className="nav-link"
                      
                      role="button"
                      data-bs-toggle="dropdown"
                    >
                      <div className="header-info">
                        <span style={{textTransform: "capitalize"}}>{getDetailDistributor?.first_name} {getDetailDistributor?.last_name}</span>
                      </div>
                      <img src={getDetailDistributor?.profile_picture} width={20} alt="" />
                    </Link>

                    <div className="dropdown-menu dropdown-menu-end">
                      <Link to="/profile" className="dropdown-item ai-icon">
                        <svg
                          id="icon-user1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-primary"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                        <span className="ms-2">Profile </span>
                      </Link>
                      <button
                        className="dropdown-item ai-icon"
                        onClick={handleDistributorLogout}
                      >
                        <svg
                          id="icon-logout"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-danger"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                          <polyline points="16 17 21 12 16 7" />
                          <line x1={21} y1={12} x2={9} y2={12} />
                        </svg>
                        <span className="ms-2">Logout </span>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default DistributorHeader;
