import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideBar from "./SideBar";

import { useNavigate, Link } from "react-router-dom";
import {
  delete_distributor_byId,
  get_distributor,
  updateDistributor,
} from "../services/web/webservices";
import { Store } from "react-notifications-component";
function Managedistributor() {
  const navigate = useNavigate();
  const [getDistributorList, setDistributorList] = useState([]);

  const getDistributor = async () => {
    try {
      const res = await get_distributor();

      if (!res || !res.data) {
        return;
      }

      setDistributorList(res.data.data);
    } catch (error) {
      // console.error("API request failed:", error);

      Store.addNotification({
        title: "Error!",
        message: error?.response?.data?.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  useEffect(() => {
    getDistributor();
  }, []);

  const handleDelete = (id) => {
    if (window.confirm("Are You Sure To Delete User?")) {
      delete_distributor_byId(id)
        .then((res) => {
          getDistributor();
          Store.addNotification({
            title: "Success",
            message: res?.data?.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        })
        .catch((err) => {
          if (err) {
            Store.addNotification({
              title: "Error!",
              message: err?.response?.data?.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        });
    }
  };

  const [isToggled, setIsToggled] = useState(false);
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };

  const [selectedStatus, setSelectedStatus] = useState("");

  const handleActiveInactiveChange = (e, item) => {
    const selectedValue = e.target.value;
    setSelectedStatus(selectedValue);

    const selectedId = item.id;

    updateDistributor({ status: selectedValue }, selectedId)
      .then((res) => {
        let msg =
          "Distributor " +
          (selectedValue == "inactive" ? "Inactivated" : "Activated") +
          " Successfully";
        Store.addNotification({
          title: "Success",
          message: msg,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        getDistributor();
      })
      .catch((error) => {
        if (error) {
          Store.addNotification({
            title: "Error!",
            message: error?.response?.data?.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      });
  };

  const css = `
.table-container {
  overflow-x: auto; 
  overflow-y: auto; 

}

`;
  return (
    <>
      <div
        id="main-wrapper"
        className={isToggled ? "show menu-toggle" : "show"}
      >
        <div className="nav-header">
          <Link to="/home" className="brand-logoo">
            <img
              className="logo-abbr"
              src="images/fast-delivery.png"
              alt="logo"
            />
            <h3 className="nav-text">Boy Blue Delivery</h3>
          </Link>
          <div className="nav-control">
            <div
              className={isToggled ? "hamburger is-active" : "hamburger"}
              onClick={toggleClass}
            >
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </div>
          </div>
        </div>
        <Header pageName="Manage Distributor" />

        <SideBar />
        <style>{css}</style>
        <div className="table-container">
          <div className="content-body default-height default-height">
            <div className="container-fluid">
              <div className="form-head d-flex mb-3 align-items-start flex-wrap justify-content-between">
                <div className="me-auto d-lg-block">
                  <h3 className="text-primary font-w600"></h3>
                </div>
              </div>

              <div className="row">
                {/* <script src="js/script.js"></script> */}

                <div className="col-lg-12 col-md-12">
                  <div className="upload-btn">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3>Manage Distributor</h3>
                    </div>
                    <div className="table-container">
                      <table className="table table-responsive csv-file-section Details">
                        <thead>
                          <tr className="upper-heading">
                            <th className="u-heading">First Name</th>
                            <th className="u-heading">Last Name</th>
                            <th className="u-heading">Email Id</th>
                            <th className="u-heading">Status</th>
                            <th className="u-heading">Mobile Number</th>

                            <th className="u-heading">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getDistributorList.map((rowData, index) => (
                            <tr key={index} className="lower-heading">
                              <td
                                className="u-heading"
                                style={{ textTransform: "capitalize" }}
                              >
                                {rowData.first_name}
                              </td>
                              <td
                                className="u-heading"
                                style={{ textTransform: "capitalize" }}
                              >
                                {rowData.last_name}
                              </td>
                              <td className="u-heading">{rowData.email}</td>

                              <td>
                                <span className="name-input">
                                  <select
                                    style={{
                                      border: "1px solid #c6c6c6",
                                      borderRadius: "8px",
                                      padding: "10px",
                                    }}
                                    className="input-tex"
                                    name="status"
                                    onChange={(e) => {
                                      handleActiveInactiveChange(e, rowData);
                                    }}
                                    value={
                                      rowData?.status === "active"
                                        ? "active"
                                        : "inactive"
                                    }
                                  >
                                    <option value="active">Active</option>
                                    <option value="inactive">In Active</option>
                                  </select>
                                </span>
                              </td>

                              <td className="u-heading">
                                {rowData.mobile_number}
                              </td>

                              <td className="u-heading">
                                <div
                                  className="edit-delete-container"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/editManageDistributor/${rowData.id}`
                                      )
                                    }
                                    style={{
                                      marginRight: "10px",
                                      textDecoration: "none",
                                      color: "#0f5a94",
                                    }}
                                  >
                                    <i className="fa-solid fa-pen-to-square"></i>
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleDelete(rowData.id);
                                    }}
                                    style={{
                                      textDecoration: "none",
                                      color: "#0f5a94",
                                    }}
                                  >
                                    <i
                                      className="fa-solid fa-trash"
                                      style={{ marginLeft: "10px" }}
                                    ></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Managedistributor;
