import React, { useState, useRef, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { get_profile, update_profile } from "../services/web/webservices";
import { Store } from "react-notifications-component";

function InerSideBar(props) {
  const navigate = useNavigate();
  const [getDetail, setDetail] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setProfileImage(file);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("profile_picture", profileImage);
   

    update_profile(formData)
      .then((res) => {
        Store.addNotification({
          title: "Success",
          message: res?.data?.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        navigate("/profile");
       
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    get_profile()
      .then((res) => {
    
        setDetail(res.data.data);
      })
      .catch((err) => {
        if (err) {
      
        }
      });
  }, []);

  const css=`
  .active, .account-box-1:hover{
    background-color: #0f5a94;
       color: white !important;
   }
   .active, .account-box-1:hover h6{
    
       color: white !important;
   }
   
   .account-box-1.active h6{
      color: white !important;
   }
  `;
  
  return (
    <>
      <style>{css}</style>
      <div className="signin-page profile">
        <form className="savebtn">
          <div className="avatar-upload">
            <div className="avatar-edit">
              <input
                type="file"
                id="imageUpload"
                accept=".png, .jpg, .jpeg"
                name="profile_picture"
                onChange={handleImageChange}
              />
              <label className="toggle" htmlFor="imageUpload"></label>
            </div>
            <div className="avatar-preview">
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    width: "110px",
                    height: "110px",
                    borderRadius: "100%",
                    padding: "0",
                  }}
                />
              ) : (
                <div
                  id="imagePreview"
                  style={{
                    backgroundImage: `url(${getDetail?.profile_picture})`,
                  }}
                ></div>
              )}
            </div>
          </div>

          {imagePreview && (
            <button
              type="submit"
              style={{padding:"5px" }}
              onClick={handleSubmit}
            >
              <div className="button-container">
                <span className="button ok filter">save</span>
              </div>
            </button>
          )}
        </form>

        <div className="myaccount-information" id="myDIV">
          <NavLink   to="/profile" className="account-box-1">
            <h6>Profile</h6>
            <i className="fa-solid fa-chevron-right" />
          </NavLink>

          <NavLink
            to="/editProfile"
            className="account-box-1"
            // activeClassName="active"
          >
            <h6>Edit Profile</h6>
            <i className="fa-solid fa-chevron-right" />
          </NavLink>

          <NavLink
            to="/changePassword"
            className="account-box-1"
            // activeClassName="active"
          >
            <h6>Change Password</h6>
            <i className="fa-solid fa-chevron-right" />
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default InerSideBar;
