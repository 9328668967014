import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Store } from "react-notifications-component";
import InerSideBar from "./InerSideBar";
import Header from "./Header";
import SideBar from "./SideBar";
import DistributorHeader from "./DistributorHeader";
import { MyTextInput } from "../services/authentication/inputServices";
import { edit_profile, get_profile } from "../services/web/webservices";
import { useNavigate, Link } from "react-router-dom";


function EditProfile(props) {
  const [getEditDetail, setEditDetail] = useState("");
  const role = localStorage.getItem("role");
  const [getButton, setButton] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    get_profile()
      .then((res) => {
       
        setEditDetail(res.data.data);
      })
      .catch((err) => {
        if (err) {
          // console.log("------>", err);
        }
      });
  }, []);

  const [isToggled, setIsToggled] = useState(false);
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };

  return (
    <>
      <div
        id="main-wrapper"
        className={isToggled ? "show menu-toggle" : "show"}
      >
        {role === "admin" ? (
          <>
            <div className="nav-header">
              <Link to="/home" className="brand-logoo">
                <img
                  className="logo-abbr"
                  src="images/fast-delivery.png"
                  alt="logo"
                />
                <h3 className="nav-text">Boy Blue Delivery</h3>
              </Link>
              <div className="nav-control">
                <div
                  className={isToggled ? "hamburger is-active" : "hamburger"}
                  onClick={toggleClass}
                >
                  <span className="line" />
                  <span className="line" />
                  <span className="line" />
                </div>
              </div>
            </div>
            <Header pageName="Edit Profile" />
            <SideBar />
          </>
        ) : (
          <>
            <DistributorHeader />
          </>
        )}
        <div className="content-body default-height default-height">
          <div className="container-fluid">
            <div className="form-head d-flex mb-3 align-items-start flex-wrap justify-content-between">
              <div className="me-auto d-lg-block">
                <h3 className="text-primary font-w600" />
              </div>
            </div>
            <section className="content profile mb-5 mt-5">
              <div className="checkout-sign">
                <div className="signin-form">
                  <InerSideBar />

                  <div className="signin-page-1">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        first_name: getEditDetail?.first_name
                          ? getEditDetail?.first_name
                          : "",
                        last_name: getEditDetail?.last_name
                          ? getEditDetail?.last_name
                          : "",
                        email: getEditDetail?.email ? getEditDetail?.email : "",
                        mobile_number: getEditDetail?.mobile_number
                          ? getEditDetail?.mobile_number
                          : "",
                      }}
                      validationSchema={Yup.object({
                        first_name: Yup.string().required(" First Name is required"),
                        last_name: Yup.string(),

                        email: Yup.string()
                          .email("Invalid email")
                          .required("Email is required"),
                        mobile_number: Yup.string()
                          .matches(/^[0-9]{8,16}$/, "Invalid mobile number")
                          .test(
                            "len",
                            "Mobile number must be at least 8 digits",
                            (val) => val && val.length >= 8
                          ),
                      })}
                      onSubmit={(values, { resetForm }) => {
                        setButton(false);
                        edit_profile(values, localStorage.getItem("auth_token"))
                          .then((res) => {
                            setButton(true);
                           
                            Store.addNotification({
                              title: "Success",
                              message: res?.data?.message,
                              type: "success",
                              insert: "top",
                              container: "top-right",
                              animationIn: [
                                "animate__animated",
                                "animate__fadeIn",
                              ],
                              animationOut: [
                                "animate__animated",
                                "animate__fadeOut",
                              ],
                              dismiss: {
                                duration: 5000,
                                onScreen: true,
                              },
                            });
                            resetForm({ values: "" });

                            navigate("/profile");
                          })
                          .catch((err) => {
                            setButton(true);
                            if (err) {
                           
                              Store.addNotification({
                                title: "Error!",
                                message: err?.response?.data?.message,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: [
                                  "animate__animated",
                                  "animate__fadeIn",
                                ],
                                animationOut: [
                                  "animate__animated",
                                  "animate__fadeOut",
                                ],
                                dismiss: {
                                  duration: 5000,
                                  onScreen: true,
                                },
                              });
                            }
                          });
                      }}
                    >
                      {(props) => (
                        <Form className="signin-form-page profile-page">
                          <div className="signin-head">
                            <h2>Edit Profile</h2>
                          </div>

                          <div className="detail-grid-1 profile">
                            <div className="form-group p-information">
                              <label>Name</label>
                              <MyTextInput
                                type="text"
                                placeholder="Enter Your First Name"
                                className="detail-btn"
                                name="first_name"
                              />
                            </div>
                            <div className="form-group p-information">
                              <label>Last Name</label>
                              <MyTextInput
                                type="text"
                                placeholder="Enter Your Last Name"
                                className="detail-btn"
                                name="last_name"
                              />
                            </div>
                            <div className="form-group p-information">
                              <label>Email Id</label>
                              <MyTextInput
                                type="email"
                                placeholder="Enter Your Email"
                                name="email"
                                className="detail-btn"
                              />
                            </div>
                            <div className="form-group p-information">
                              <label>Mobile No.</label>
                              <MyTextInput
                                type="number"
                                placeholder="Enter Your Mobile Number"
                                className="detail-btn"
                                name="mobile_number"
                              />
                            </div>
                          </div>

                          {getButton === true ? (
                            <div className="signin-button">
                              <button
                                type="submit"
                                className="learn-more-btn-1-1"
                              >
                                Submit
                              </button>
                            </div>
                          ) : (
                            <div className="signin-button">
                              <button
                                type="submit"
                                className="learn-more-btn-1-1"
                              >
                                Wait Please...
                              </button>
                            </div>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              <section className="col-lg-5 connectedSortable ui-sortable"></section>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditProfile;
