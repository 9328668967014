import React, { useState } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import { Formik, Form } from "formik";
import { Store } from "react-notifications-component";
import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import { MyTextInput } from "../services/authentication/inputServices";
import { create_distributor } from "../services/web/webservices";

function CreateDistributor() {
  const navigate = useNavigate();

  const [getButton, setButton] = useState(true);
  const [isToggled, setIsToggled] = useState(false);
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };

  return (
    <>
      <div
        id="main-wrapper"
        className={isToggled ? "show menu-toggle" : "show"}
      >
        <div className="nav-header">
          <Link to="/home" className="brand-logoo">
            <img
              className="logo-abbr"
              src="images/fast-delivery.png"
              alt="logo"
            />
            <h3 className="nav-text">Boy Blue Delivery</h3>
          </Link>
          <div className="nav-control">
            <div
              className={isToggled ? "hamburger is-active" : "hamburger"}
              onClick={toggleClass}
            >
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </div>
          </div>
        </div>
        <SideBar />
        <Header pageName="Create Distributor" />
        <div className="content-body default-height default-height">
          <div className="container-fluid">
            <div className="form-head d-flex mb-3 align-items-start flex-wrap justify-content-between">
              <div className="me-auto d-lg-block">
                <h3 className="text-primary font-w600">
                  Welcome to Boy Blue Delivery!
                </h3>
              </div>
            </div>
            <div className="signin-page-1">
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  email: "",
                  mobile_number: "",
                  address: "",
                }}
                validationSchema={Yup.object({
                  first_name: Yup.string()
                    .trim()
                    .required("Please Enter First Name")
                    .max(50, "First Name must be at most 50 characters long")
                    .test(
                      "notEmpty",
                      "Please enter a valid First Name",
                      (value) => {
                        return value.trim().length > 0 || value === "";
                      }
                    ),
                  last_name: Yup.string().max(
                    50,
                    "Last Name must be at most 50 characters long"
                  ),
                  email: Yup.string()
                    .matches(
                      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      "Please Enter Valid Email"
                    )
                    .required("Please Enter Email"),
                  mobile_number: Yup.string()
                    .matches(
                      /^[0-9]{8,16}$/,
                      "Mobile number should be 8-16 characters"
                    )
                    .test(
                      "len",
                      "Mobile number should be 8-16 characters",
                      (val) => val && val.length >= 8
                    ),
                  address: Yup.string(),
                })}
                onSubmit={(values, { resetForm }) => {
                  setButton(false);
                  create_distributor(values)
                    .then((res) => {
                      setButton(true);
                      
                      Store.addNotification({
                        title: "Success",
                        message: res?.data?.message,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true,
                        },
                      });
                      resetForm({ values: "" });
                      navigate("/manageDistributor");
                    })
                    .catch((err) => {
                      setButton(true);
                      if (err) {
                      
                        Store.addNotification({
                          title: "Error!",
                          message: err?.response?.data?.message,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: [
                            "animate__animated",
                            "animate__fadeOut",
                          ],
                          dismiss: {
                            duration: 5000,
                            onScreen: true,
                          },
                        });
                      }
                    });
                }}
              >
                {(props) => (
                  <Form className="signin-form-page profile-page">
                    <div className="signin-head">
                      <h2>Create Distributor</h2>
                    </div>
                    <div className="detail-grid-1 profile">
                      <div className="form-group p-information">
                        <label>First Name</label>
                        <MyTextInput
                          type="text"
                          placeholder="First Name"
                          name="first_name"
                          className="detail-btn"
                        />
                      </div>
                      <div className="form-group p-information">
                        <label>Last Name</label>
                        <MyTextInput
                          type="text"
                          placeholder="Last Name"
                          name="last_name"
                          className="detail-btn"
                        />
                      </div>
                      <div className="form-group p-information">
                        <label>Email Id</label>
                        <MyTextInput
                          type="email"
                          placeholder="Email Id"
                          name="email"
                          className="detail-btn"
                        />
                      </div>
                      <div className="form-group p-information">
                        <label>Mobile No.</label>
                        <MyTextInput
                          type="number"
                          placeholder="Mobile No"
                          name="mobile_number"
                          className="detail-btn"
                        />
                      </div>
                      <div className="form-group p-information">
                        <label>Address</label>
                        <MyTextInput
                          type="string"
                          placeholder="Address"
                          name="address"
                          className="detail-btn"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 offset-md-4">
                        <div className="signin-button">
                          {getButton === true ? (
                            <button
                              type="submit"
                              className="learn-more-btn-1-1"
                            >
                              Submit
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="learn-more-btn-1-1"
                            >
                              Wait Please
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateDistributor;
