import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { get_profile } from "../services/web/webservices";
import SideBar from "./SideBar";
import Header from "./Header";
import InerSideBar from "./InerSideBar";
import DistributorHeader from "./DistributorHeader";

function Profile() {
  const role = localStorage.getItem("role");
  const [getDetail, setDetail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    get_profile()
      .then((res) => {
       
        setDetail(res.data.data);
      })
      .catch((err) => {
        if (err) {
          // console.log("------>", err);
        }
      });
  }, []);

  const [isToggled, setIsToggled] = useState(false);
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };

  return (
    <>
      <div
        id="main-wrapper"
        className={isToggled ? "show menu-toggle" : "show"}
      >
        {role === "admin" ? (
          <>
            <div className="nav-header">
              <Link to="/home" className="brand-logoo">
                <img
                  className="logo-abbr"
                  src="images/fast-delivery.png"
                  alt="logo"
                />
                <h3 className="nav-text">Boy Blue Delivery</h3>
              </Link>
              <div className="nav-control">
                <div
                  className={isToggled ? "hamburger is-active" : "hamburger"}
                  onClick={toggleClass}
                >
                  <span className="line" />
                  <span className="line" />
                  <span className="line" />
                </div>
              </div>
            </div>
            <Header pageName="Profile" getDetail={getDetail} />

            <SideBar />
          </>
        ) : (
          <>
            <DistributorHeader />
          </>
        )}
        <div className="content-body default-height default-height">
          <div className="container-fluid">
            <div className="form-head d-flex mb-3 align-items-start flex-wrap justify-content-between">
              <div className="me-auto d-lg-block">
                <h3 className="text-primary font-w600" />
              </div>
            </div>

            <section className="content profile mb-5 mt-5">
              <div className="checkout-sign">
                <div className="signin-form">
                  <InerSideBar getDetail={getDetail} />

                  <div className="signin-page-1" >
                    <form className="signin-form-page profile-page">
                      <div className="signin-head">
                        <h2>Personal Information</h2>
                      </div>
                      <div className="detail-grid-1 profile">
                        <div className="form-group p-information">
                          <label>First Name</label>
                          <div
                            className="firstname-details"
                            style={{ marginTop: "10px" }}
                          >
                            <span>{getDetail.first_name}</span>
                          </div>
                        </div>
                        <div className="form-group p-information">
                          <label>Last Name</label>
                          <div
                            className="firstname-details"
                            style={{ marginTop: "10px" }}
                          >
                            <span>{getDetail.last_name}</span>
                          </div>
                        </div>
                        <div
                          className="form-group p-information"
                          style={{ marginTop: "20px" }}
                        >
                          <label>Email Id</label>
                          <div
                            className="firstname-details"
                            style={{ marginTop: "10px" }}
                          >
                            <span>{getDetail.email}</span>
                          </div>
                        </div>
                        <div
                          className="form-group p-information"
                          style={{ marginTop: "20px" }}
                        >
                          <label>Mobile No.</label>
                          <div
                            className="firstname-details"
                            style={{ marginTop: "10px" }}
                          >
                            <span>{getDetail.mobile_number}</span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <section className="col-lg-5 connectedSortable ui-sortable"></section>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
