import React, { useState, useEffect } from "react";
import Header from "../../src/Component/Header";
import SideBar from "../../src/Component/SideBar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Store } from "react-notifications-component";
import { useNavigate, Link } from "react-router-dom";
import { MyTextInput } from "../services/authentication/inputServices";
import { useParams } from "react-router-dom";

import {
  getDistributorByID,
  updateDistributor,
} from "../services/web/webservices";

function EditManageDistributor() {
  const navigate = useNavigate();
  const [getButton, setButton] = useState(true);
  const [getProductbyId, setProductbyId] = useState([]);
  const { userId } = useParams();
 

  useEffect(() => {
    getDistributorByID(userId)
      .then((res) => {
        setProductbyId(res.data.data);
      })
      .catch((err) => {
        if (err) {
        }
      });
  }, []);

  const css = `
  .table-container {
    overflow-x: auto; /* Horizontal scrollbar when table overflows */
    overflow-y: auto; /* Vertical scrollbar when table overflows */
  
  }

  `;

  const [isToggled, setIsToggled] = useState(false);
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };

  return (
    <>
      <div
        id="main-wrapper"
        className={isToggled ? "show menu-toggle" : "show"}
      >
        <div className="nav-header">
          <Link to="/home" className="brand-logoo">
            <img
              className="logo-abbr"
              src="images/fast-delivery.png"
              alt="logo"
            />
            <h3 className="nav-text">Boy Blue Delivery</h3>
          </Link>
          <div className="nav-control">
            <div
              className={isToggled ? "hamburger is-active" : "hamburger"}
              onClick={toggleClass}
            >
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </div>
          </div>
        </div>
        <Header pageName="Manage Distributor" />

        <SideBar />
        <div className="content-body default-height default-height">
          <div className="container-fluid">
            <div className="form-head d-flex mb-3 align-items-start flex-wrap justify-content-between">
              <div className="me-auto d-lg-block"></div>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={{
                first_name: getProductbyId?.first_name
                  ? getProductbyId?.first_name
                  : "",
                last_name: getProductbyId?.last_name
                  ? getProductbyId?.last_name
                  : "",
                email: getProductbyId?.email ? getProductbyId?.email : "",
                mobile_number: getProductbyId?.mobile_number
                  ? getProductbyId?.mobile_number
                  : "",
                address: getProductbyId?.address ? getProductbyId?.address : "",
              }}
              validationSchema={Yup.object({
                first_name: Yup.string().required("First Name is required"),
                last_name: Yup.string(),
                email: Yup.string()
                  .email("Please Enter Email")
                  .matches(
                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    "Please Enter Valid Email"
                  )
                  .required("Please Enter Email"),
                mobile_number: Yup.string()
                  .matches(/^[0-9]{8,16}$/, "Invalid mobile number")
                  .test(
                    "len",
                    "Mobile number should be 8-16 characters",
                    (val) => val && val.length >= 8
                  ),
              })}
              onSubmit={(values, { resetForm }) => {
                
                setButton(false);

                updateDistributor(values, userId)
                  .then((res) => {
                    setButton(true);

                    Store.addNotification({
                      title: "Success",
                      message: res?.data?.message,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                      },
                    });

                    resetForm({ values: "" });
                    navigate("/manageDistributor");
                  })
                  .catch((error) => {
                    setButton(true);
                    if (error) {
                      Store.addNotification({
                        title: "Error!",
                        message: error?.response?.data?.message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true,
                        },
                      });
                    }
                  });
              }}
            >
              {(props) => (
                <div className="signin-page-1">
                  <Form className="signin-form-page profile-page">
                    <div className="signin-head">
                      <h2>Manage Distributor</h2>
                    </div>

                    <div className="detail-grid-1 profile">
                      <div className="form-group p-information">
                        <label>First Name</label>
                        <MyTextInput
                          type="text"
                          placeholder="First Name"
                          name="first_name"
                          className="detail-btn"
                          
                        />
                      </div>
                      <div className="form-group p-information">
                        <label>Last Name</label>
                        <MyTextInput
                          type="text"
                          placeholder="Last Name"
                          name="last_name"
                          className="detail-btn"
                        />
                      </div>
                      <div className="form-group p-information">
                        <label>Email Id</label>
                        <MyTextInput
                          type="text"
                          placeholder="Email Id"
                          name="email"
                          className="detail-btn"
                        />
                      </div>
                      <div className="form-group p-information">
                        <label>Mobile No.</label>
                        <MyTextInput
                          type="number"
                          placeholder="Mobile Number"
                          name="mobile_number"
                          className="detail-btn"
                        />
                      </div>
                      <div className="form-group p-information">
                        <label>Address</label>
                        <MyTextInput
                          type="string"
                          placeholder="Address"
                          name="address"
                          className="detail-btn"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 offset-md-4">
                        <div className="signin-button">
                          {getButton === true ? (
                            <button
                              type="submit"
                              className="learn-more-btn-1-1"
                              id="place_order"
                              data-value="Place order"
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="learn-more-btn-1-1"
                              id="place_order"
                              data-value="Place order"
                            >
                              wait please!!
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditManageDistributor;
