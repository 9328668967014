import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { MyTextInput } from "../services/authentication/inputServices";
import { Store } from "react-notifications-component";
import { useNavigate } from "react-router-dom";
import { forgot } from "../services/web/webservices";

function ForgetPassword() {
  const navigate = useNavigate();
  const [getButton, setButton] = useState(true);
  return (
    <>
      <div className="sign-screen"> 
        <section className="sectionn">
          <div className="container-fluid-1">
            <div className="row">
              <div className="col-md-6 padding-1">
                <div className="form-part">
                  <p
                    className="nav-text"
                    style={{ color: "#0f5a94", fontWeight: "bold" }}
                  >
                    Boy Blue Delivery
                  </p>
                  <h2 className="mb-5">FORGOT PASSWORD</h2>

                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string()
                        .email("Please Enter Email")
                        .required("Please Enter Email"),
                    })}
                    onSubmit={(values, { resetForm }) => {
                      setButton(false);
                      forgot(values)
                        .then((res) => {
                          setButton(true);
                         
                          Store.addNotification({
                            title: "Success",
                            message: res?.data?.message,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: [
                              "animate__animated",
                              "animate__fadeIn",
                            ],
                            animationOut: [
                              "animate__animated",
                              "animate__fadeOut",
                            ],
                            dismiss: {
                              duration: 5000,
                              onScreen: true,
                            },
                          });
                          resetForm({ values: "" });
                          navigate("/resetPassword");
                        })
                        .catch((err) => {
                          setButton(true);
                          if (err) {
                           
                            Store.addNotification({
                              title: "Error!",
                              message: err?.response?.data?.message,
                              type: "danger",
                              insert: "top",
                              container: "top-right",
                              animationIn: [
                                "animate__animated",
                                "animate__fadeIn",
                              ],
                              animationOut: [
                                "animate__animated",
                                "animate__fadeOut",
                              ],
                              dismiss: {
                                duration: 5000,
                                onScreen: true,
                              },
                            });
                          }
                        });
                    }}
                  >
                    {(props) => (
                      <div className="right col">
                        <Form className="sign-up">
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="signup__field">
                                <MyTextInput
                                  className="signup__input"
                                  type="email"
                                  name="email"
                                  id="email"
                                  placeholder="Email"
                                  required
                                />
                                {/* <label
                                  className="signup__label"
                                  htmlFor="email"
                                >
                                  Email
                                </label> */}
                              </div>
                            </div>
                          </div>
                          {/* <button type="submit" className="btn submit-btn">
                          <a href="#">Submit</a>
                        </button> */}
                          {getButton === true ? (
                            <button type="submit" className="btn submit-btn">
                              Submit
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn submit-btn"
                              disabled={!getButton}
                            >
                              Wait Please
                            </button>
                          )}
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="col-md-6 bg-grey">
                <div className="text-part text-center pt-5">
                  <img src="images/login-1.png" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ForgetPassword;
