import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import { verifyemail } from "../services/web/webservices";

function EmailVerification() {  
  const { token } = useParams();
 
  const navigate = useNavigate();
  const type = "password";

  useEffect(() => {
    verifyemail(token)
      .then((res) => {
      
        Store.addNotification({
          title: "Success",
          message: res?.data?.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        localStorage.setItem("reset_forget_token", res?.data?.token);
        navigate("/resetPassword");
      })

      .catch((err) => {
        Store.addNotification({
          title: "Error!",
          message: err?.response?.data?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
      // navigate("/resetPassword");
      navigate("/forgotPassword");
  }, []);

  //return <></>;
}

export default EmailVerification;
