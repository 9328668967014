const BaseUrl = {
  base_url: `https://1click2ship.com/delivery_app`,
  api_url: `https://1click2ship.com`,
  image_url: `https://1click2ship.com/images`
  
  //  base_url:`http://34.196.236.29:10045/delivery_app`,
  //  api_url: `http://34.196.236.29:10045`,
  //  image_url: `http://34.196.236.29:10045/images`

    // base_url:`http://localhost:10045/delivery_app`,
    // api_url: `http://localhost:10045`,
    // image_url: `http://localhost:10045/images`
};
export default BaseUrl;
