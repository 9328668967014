import AllRoutes from "../src/routes/AllRoutes";
import { BrowserRouter } from "react-router-dom";
import "./App.css";

import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

function App() {
  return (
    <>
      <BrowserRouter basename='/delivery_app/'>
        <ReactNotifications />
        <AllRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
