import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { MyTextInput } from "../services/authentication/inputServices";
import { Store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { login } from "../services/web/webservices";

function Login() {
  const navigate = useNavigate();
  const [getButton, setButton] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const css = `
  .signup__field {
    position: relative;
  }
  
  .input-container {
    position: relative;
  }
  
  .signup__input {
    padding-right: 30px; /* Adjust as needed */
  }
  
  .toggle-eye {
    position: absolute;
    top: 50%;
    right: 30px; /* Adjust as needed */
    transform: translateY(-50%);
    cursor: pointer;
  }
  `;
  return (
    <>
      <style>{css}</style>
      <div className="sign-screen">
        <section className="sectionn">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 padding-1">
                <div className="form-part">
                  <p
                    className="nav-text"
                    style={{ color: "#0f5a94", fontWeight: "bold" }}
                  >
                    Boy Blue Delivery
                  </p>
                  <h2 className="mb-5">LOGIN</h2>
                  {/* <p className="nav-text" style={{color:"blue" }}>Boy Blue Delivery</p> */}

                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string()
                        .email("Please Enter Email")
                        .matches(
                          /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          "Please Enter Valid Email"
                        )
                        .required("Please Enter Email"),
                      password: Yup.string()
                        // .min(8, "Password must be at least 8 characters long")
                        // .max(16, "Password must be at most 16 characters long")
                        // .matches(
                        //   /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/,
                        //   "Password must contain at least one number, one special character, one uppercase letter, and one lowercase letter"
                        // )
                        .required("Please Enter Password"),
                    })}
                    onSubmit={(values, { resetForm }) => {
                      setButton(false);
                    
                      login(values)
                        .then((res) => {
                          setButton(true);
                        

                          Store.addNotification({
                            title: "Success",
                            message: res?.data?.message,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: [
                              "animate__animated",
                              "animate__fadeIn",
                            ],
                            animationOut: [
                              "animate__animated",
                              "animate__fadeOut",
                            ],
                            dismiss: {
                              duration: 5000,
                              onScreen: true,
                            },
                          });
                          resetForm({ values: "" });
                          localStorage.setItem("auth_token", res?.data?.token);
                          const role = res?.data?.data?.role;
                          localStorage.setItem("role", role);

                          if (role === "admin") {
                            navigate("/home");
                          } else {
                            navigate("/distributorPackage");
                          }
                        })
                        .catch((error) => {
                          setButton(true);
                          if (error) {
                            // console.log("error", error);

                            Store.addNotification({
                              title: "Error!",
                              message: error?.response?.data?.message,
                              type: "danger",
                              insert: "top",
                              container: "top-right",
                              animationIn: [
                                "animate__animated",
                                "animate__fadeIn",
                              ],
                              animationOut: [
                                "animate__animated",
                                "animate__fadeOut",
                              ],
                              dismiss: {
                                duration: 5000,
                                onScreen: true,
                              },
                            });
                          }
                        });
                    }}
                  >
                    {(props) => (
                      <div className="right col">
                        <Form className="sign-up">
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="signup__field">
                                <MyTextInput
                                  className="signup__input"
                                  type="email"
                                  name="email"
                                  id="email"
                                  required
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="signup__field eye-toogle mb-3 ">
                                <MyTextInput
                                  className="signup__input"
                                  type={passwordVisible ? "text" : "password"}
                                  name="password"
                                  id="password"
                                  placeholder="Password"
                                />

                                <span
                                  className="toggle-eye"
                                  onClick={togglePasswordVisibility}
                                >
                                  <i
                                    className={`toggle-password fa ${
                                      passwordVisible
                                        ? "fa-eye"
                                        : "fa-eye-slash"
                                    }`}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-right align-items-center">
                              <div
                                className=" mb-3"
                                style={{ color: "#0f5a94" }}
                              >
                                <Link to="/forgotPassword">
                                  Forgot Password ?
                                </Link>
                              </div>{" "}
                            </div>
                          </div>

                          {getButton === true ? (
                            <button type="submit" className="btn submit-btn">
                              Login
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn submit-btn"
                              disabled={!getButton}
                            >
                              wait please!!
                            </button>
                          )}
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="col-md-6 bg-grey">
                <div className="text-part text-center pt-5">
                  <img src="images/login-1.png" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
